import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import "@fontsource/chilanka";

import LessonLayout from "~/layouts/lesson";

import Button from "~/components/button";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";

const Page = () => {
  return (
      <LessonLayout
        current_step={5}
        lesson={"Lesson 3"}
        color={"ms"}
        total_count={8}
        module_title="Will You Buy What They Are Selling?"
      >
        <ContentWrapper>
          <H2 underline>Step 5</H2>

          <div className="mt-5 w-2/3 flex content-end">
            <StaticImage
              alt="Hand using tablet."
              className="rounded"
              src="../../../images/lesson_3_step_5.jpg"
              loading="eager"
              placeholder="blurred"
              style={{
                filter: "drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.25))"
              }}
            />
          </div>

          <p>Sometimes you have to sort through a lot of information to separate the helpful and relevant information from the rest. Take another look at the lemonade diet cleanse website.</p>

          <p>Can you identify specific words, phrases, or statements that raise questions about the quality of the information? Are there phrases or statements that do not seem to support the claims made?</p>

          <p>Draw a table in your science notebook like the one below to record your ideas. An example is included to get you started.</p>

          <div className="bg-white p-4 rounded shadow">
            <table className="my-5" style={{borderStyle: 'none'}}>
              <thead>
                <tr>
                  <th className="pb-4 border-0 border-b border-r border-gray-800 text-left">
                    <strong className="text-xl" style={{ fontFamily: "Chilanka" }}>Idea, phrase, or statement in ad</strong>
                  </th>
                  <th className="pl-4 pb-4 border-0 border-b border-gray-800 text-left">
                    <strong className="text-xl" style={{ fontFamily: "Chilanka" }}>Question you want answered about that statement</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ height: "300px" }}>
                  <td
                    className="pt-4 text-xl text-left border-0 border-r border-gray-800"
                    style={{
                      fontFamily: "Chilanka",
                      verticalAlign: "top"
                    }}
                  >
                    Heal your cells
                  </td>
                  <td
                    className="pt-4 pl-4 text-xl text-left border-0"
                    style={{
                      fontFamily: "Chilanka",
                      verticalAlign: "top"
                    }}
                  >
                    What does it mean to heal cells, and how would that work?
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p>When you are done, your teacher will lead a class discussion so you can share some of your ideas with the class.</p>

          <Button
            action="secondary"
            color="purple"
            href="/article/lemonade-diet"
            icon="external"
            title="Lemonade diet cleanse website"
          >
            Lemonade diet cleanse website
          </Button>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
